import { ActionReducer, MetaReducer } from '@ngrx/store'
import * as AuthActions from '../components/auth/+state/auth.actions'

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === AuthActions.logout.type) {
      state = undefined
    }

    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer<any>[] = [clearState]
